import React from 'react';
import Navbars from './navbar';

const StudyMaterial = () => {
  return (
    <div>
      <Navbars></Navbars>
      <div style={{color:'white', marginTop:'100px', display: 'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center', gap:'80px'}}>
        StudyMaterial
        </div>
    </div>
  )
}

export default StudyMaterial