import React from 'react'

const Reasons = () => {
  return (
    <div>
       <p> 1. Lazy - The truth is, I am. <b>As a lazy person I know what and how to prioritize </b> .</p>
       <p> 2. Judgmental - Did he just call me judgemental !!!!. Well, to come to think of it, I might be a lil bit.<b> I am always looking for ways to improve my work</b>.</p>
       <p> 3. pushy - <b> There hasn't been a day when I haven't worked since I enrolled for the web-dev course </b>. I took this up as a full-time job that required undivided attention and commitment.</p>
       <p> 4. Forgetful - Sometimes, my brain acts way older than it is. Hence, <b>everything is digitally noted </b>, less I forget where I have kept my diary.</p>
       <p> 5. Shamless - Shamlelessly, reach out for help and ask questions. I think I had rather ask twice than be in the dark.</p>
    </div>
  )
}

export default Reasons